import { Rating } from "@mui/material";
import {
  OfferPackageTour,
  TourPackageFilterOptionSelections,
  TourPackageFilters,
  TourPackageSortOptionsSelections,
} from "api/types";
import SelectFilter from "components/common/Filters/SelectFilter";
import Checkbox from "components/ui/Checkbox/Checkbox";
import RadioGroup from "components/ui/RadioGroup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

interface Props {
  sort: TourPackageSortOptionsSelections;
  setSort: Dispatch<SetStateAction<TourPackageSortOptionsSelections>>;
  filters: TourPackageFilters | null;
  setFilters: Dispatch<SetStateAction<TourPackageFilters | null>>;
}

const FilterComponent = ({ sort, setSort, filters, setFilters }: Props) => {
  const { t } = useTranslation();
  const [order, setorder] = useState<string>("");
  const sortobject= {
    price: { order: 'ASC', active: false },
    review: { order: 'ASC', active: false },
    star: { order: 'ASC', active: false }
  }
  const updateSortObject = () => {
    let updatedSortObject = { ...sortobject };
    switch (order) {
      case 'price-asc':
        updatedSortObject = {...sortobject, price: { order: 'ASC', active: true } };
        break;
      case 'price-desc':
        updatedSortObject = {...sortobject, price: { order: 'DESC', active: true } };
        break;
        case 'review-asc':
          updatedSortObject = { ...sortobject, review: { order: 'ASC', active: true } };
          break;
        case 'review-desc':
          updatedSortObject = { ...sortobject, review: { order: 'DESC', active: true } };
          break;
      case 'star-asc':
        updatedSortObject = {...sortobject, star: { order: 'ASC', active: true } };
        break;
      case 'star-desc':
        updatedSortObject = { ...sortobject,star: { order: 'DESC', active: true } };
        break;
      default:
        updatedSortObject = { ...sortobject };
        break;
    }
    setSort(updatedSortObject);
  };

    useEffect(() => {
   
      updateSortObject();
    }, [order]);
   
  const orderSelectOptions = [
    { label: t("react.filter.sortby"), value: "" },
    { label: t("react.filter.price.ascending"), value: "price-asc" },
    { label: t("react.filter.price.descending"), value: "price-desc" },
    { label: t("react.filter.rating.ascending"), value: "review-asc" },
    { label: t("react.filter.rating.descending"), value: "review-desc" },
    { label: t("react.hotel.stars-ascending"), value: "star-asc" },
    { label: t("react.hotel.stars-descending"), value: "star-desc" },

  ];

  return (
<div className="scrollbar-hide sticky top-[60px] overflow-y-auto max-h-[calc(100vh-60px)]">
<SelectFilter
        className="bg-white mb-3"
        value={order}
        setValue={setorder}
        selectOptions={orderSelectOptions}
      /> 
       <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
  {filters && filters.hotelName && (
    <CheckboxFilterGroup
      data={filters.board.data}
      name="hotelname"
      type={filters.hotelName.type}
      filters={filters}
      setFilters={setFilters}
    />
  )}
</div>
<div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.star.data}
            name="star"
            type={filters.star.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
      <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.board.data}
            name="board"
            type={filters.board.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
      <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.tourOperator.data}
            name="tourOperator"
            type={filters.board.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
      <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.popular.data}
            name="popular"
            type={filters.popular.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
      <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.price.data}
            name="price"
            type={filters.price.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
      <div className="pace-y-3 mb-1 rounded border bg-white px-4 py-2 shadow-sm">
        {filters && (
          <CheckboxFilterGroup
            data={filters.recommendation.data}
            name="recommendation"
            type={filters.recommendation.type}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
   
    </div>
  );
};

interface FilterProps {
  data: TourPackageFilterOptionSelections[] ;
  name: "board" | "popular" | "price" | "recommendation" | "star" | "hotelname" | "tourOperator";
  type: string;
  filters: TourPackageFilters | null;
  setFilters: Dispatch<SetStateAction<TourPackageFilters | null>>;
}

const CheckboxFilterGroup = ({
  data,
  name,
  type,
  filters,
  setFilters,
}: FilterProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>("all");
  const [recommendation, setRecommendation] = useState<string>("");
  const [star, setStar] = useState<number | null>(null);

  useEffect(() => {
    if (filters) {
      let newFilters = { ...filters };

      if (newFilters.recommendation) {
        newFilters.recommendation.data.forEach((item) => {
          if (item.value === recommendation) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      }

      setFilters(newFilters);
    }
  }, [recommendation]);

  useEffect(() => {
    if (filters) {
      let newFilters = { ...filters };

      if (newFilters.star) {
        if (star === null) {
          newFilters.star.data.forEach((item) => {
            if (item.value === "all") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
        } else {
          newFilters.star.data.forEach((item) => {
            if (item.value === star?.toString()) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
        }

        setFilters(newFilters);
      }
    }
  }, [star]);
  const [textInput, setTextInput] = useState<string>("");

  // Handle text input change
  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setTextInput(newValue);
    
    if (filters && filters.hotelName) {
      let newFilters = { ...filters };
      if (newValue.length > 2) {
        newFilters.hotelName.data.value = newValue;
        setFilters(newFilters); 
      }
      if(newValue.length === 0){
        newFilters.hotelName.data.value = null;
        setFilters(newFilters); 
      }
    }
  };
  return (
    <div className="flex flex-col">
      <span className="text-lg mb-1 font-semibold flex justify-between">
        {t("react.tourpackage.filter." + name.toLowerCase())}
        { name==="recommendation" && 
        <button className="text-red-500 text-xs" onClick={() => setRecommendation("")}>{t("flights.clear")}</button>
}

      </span>
      {type === "TEXT" && (
        <input
          type="text"
          value={textInput || ""}
          onChange={handleTextInputChange}
          placeholder={t("react.tourpackage.filter.enter_value")}
          className="border border-gray-300 px-3 py-2 rounded-md mt-2"
        />
      )}
      {type === "MULTISELECT" ? (
        data.map((item, index) => {
          return (
            <Checkbox
              key={index}
              name={name + item.key}
              label={
                name === "tourOperator"
                  ? item.key
                  : t("react.tourpackage.filter." + item.key)
              }              defaultChecked={item.selected}
              onChange={(e) => {
                if (filters) {
                  let newFilters = { ...filters };

                  switch (name) {
                    case "board":
                      if (newFilters.board) {
                        newFilters.board.data[index].selected = e;
                      }
                      break;
                      case "tourOperator":
                        if (newFilters.board) {
                          newFilters.tourOperator.data[index].selected = e;
                        }
                        break;
                    case "popular":
                      if (newFilters.popular) {
                        newFilters.popular.data[index].selected = e;
                      }
                      break;
                    default:
                      break;
                  }
                  setFilters(newFilters);
                }
              }}
            />
          );
        })
      ) : type === "SELECT" ? (
        name === "recommendation" ? (
          <div className="flex">

            {data.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setRecommendation(item.value);
                  }}
                  key={index}
                  className={`flex h-12 w-12 cursor-pointer items-center justify-center border border-neutral-100 first:rounded-l last:rounded-r ${
                    recommendation === item.value
                      ? "bg-primary-6000 text-white"
                      : "bg-white text-black"
                  }`}
                >
                  <span className="text-center text-xs font-semibold">
                    {t("react.tourpackage.filter." + item.key)}
                  </span>
                </div>
              );
            })}
          </div>
        ) : name === "price" ? (
          <>
            <label className="flex cursor-pointer items-center space-x-2">
              <input
                type="radio"
                name={name}
                value={"all"}
                checked={selected === "all"}
                onChange={(e) => {
                  setSelected(e.target.value);

                  if (filters) {
                    let newFilters = { ...filters };

                    switch (name) {
                      case "price":
                        if (newFilters.price) {
                          newFilters.price.data.forEach((item) => {
                            item.selected = false;
                          });
                        }
                        break;
                      default:
                        break;
                    }

                    setFilters(newFilters);
                  }
                }}
              />
              <span className="text-sm sm:text-base text-neutral-900">
                {t("react.tourpackage.filter.all")}
              </span>
            </label>
            {data.map((item, index) => {
              return (
                <label className="flex cursor-pointer items-center space-x-2">
                  <input
                    type="radio"
                    name={name}
                    value={item.value}
                    checked={selected === item.value}
                    onChange={(e) => {
                      setSelected(e.target.value);

                      if (filters) {
                        let newFilters = { ...filters };

                        switch (name) {
                          case "price":
                            if (newFilters.price) {
                              newFilters.price.data[index].selected =
                                e.target.checked;
                            }
                            break;
                          default:
                            break;
                        }
                        setFilters(newFilters);
                      }
                    }}
                  />
                  <span className="text-sm sm:text-base text-neutral-900">
                    {t("react.tourpackage.filter." + item.key)}
                  </span>
                </label>
              );
            })}{" "}
          </>
        ) : name === "star" ? (
          <Rating
            name="simple-controlled"
            value={star}
            onChange={(event, newValue) => {
              setStar(newValue);
            }}
            size="large"
          />
        ) : null
      ) : null}
    </div>
  );
};

export default FilterComponent;
