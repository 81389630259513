import React, { Suspense } from "react";
import MyRouter from "routers/index";
import "tippy.js/dist/tippy.css"; // optional
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dates/initialize";
import { Provider } from "react-redux";
import { store } from "redux/store";
import ErrorPage from "components/common/ErrorPage/ErrorPage";
import { BrowserRouter } from "react-router-dom";
import SiteHeader from "pages/SiteHeader";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
    console.error("Error caught in boundary bbıbı:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <BrowserRouter>
          <SiteHeader/>
          <ErrorPage />
        </BrowserRouter>
      );
    }
    return this.props.children;
  }
}

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <MyRouter />
        </Suspense>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
