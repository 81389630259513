import { addFlightSegment, changePnrStatusURL, postAgenyRefundUrl, postRescanPnr, postRescanPnrtakeReservation, postValidateRefundUrl, refreshPnrURL } from "api/helpers/api-constants";
import fetcher, { fetcherWithoutJSON } from "api/helpers/fetcher";
import postFetcher from "api/helpers/postFetcher";
import { Booking, BookingFlightReservation } from "api/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useReduxReducer } from "redux/store";
import Swal from "sweetalert2";
import currencyFormat from "utils/currencyFormat";
import { disableButton } from "utils/disableButton";
import { getDateTime } from "utils/getDateTime";
import { getStaticUrl } from "utils/getStaticUrl";
import FlightTable from "./components/FlightTable";
import ReissueModal from "../components/Reissue/ReissueModal";
import SsrSection from "./components/SsrSection";
import ReservationOptionsButtons from "./components/ReservationOptions";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";
import { CircularProgress } from "@material-ui/core";
import ConfirmationDialog from "components/ui/ConfirmationDialog/ConfirmationDialog";
import UpdatePaxDialog from "./components/UpdatePaxDialog";
import UpdateFlightDialog from "./components/UpdateFlightDialog";

interface ISectionFlight {
    data: BookingFlightReservation
    bookingKey: string
   
}
 
const FlightDetail : React.FC<ISectionFlight> = ({data, bookingKey}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    const { audit }= useReduxReducer(state => state.config)
    const { isAdmin } = useReduxReducer(state => state.general)
    const [message, setMessage] = useState<string | null>(null);
    const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
    const resetNotification = () => {
      setMessage(null);
      setSeverity('info');
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    const internalButtonsFlight = (bookingKey: string, filekey: string, id: any, status: string) => {

      
      function refreshPnr(filekey: string) {
        Swal.fire({
          title: "Refresh PNR",
          html: "Troya ise, lütfen aşağıdaki haneye Troya görüntüsünü (*A) ekleyiniz:",
          input: "textarea",
        }).then((result) => {
          if (result.hasOwnProperty("value")) {
            const formData = new FormData();
            const refreshUrl = refreshPnrURL(filekey);
            formData.append("additionalData", result.value);
            formData.append("bookingKey", bookingKey);
      
            fetch(refreshUrl, {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.ok) {
                
                  Swal.fire({
                    title: "Success",
                    text: "PNR successfully refreshed.",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(() => {
                    window.location.reload(); 
                  });
                }
                 else {
                  Swal.fire({
                    title: "Error",
                    text: "Failed to refresh PNR.",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                if (error.name !== 'AbortError') { 
                  Swal.fire({
                    title: "Error",
                    text: "An unexpected error occurred.",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  console.error("Error refreshing PNR:", error);
                }
              });
          }
        });
      }
      
      function changePnrStatus(bookingKey: string, pnrId: number, currentStatus: string) {
        Swal.fire({
          title: "PNR statüsünü değiştir",
          input: "select",
          inputOptions: {
            RESERVATION: "Opsiyonlu rezervasyon",
            TICKETED: "Biletlenmiş kayıt",
            VOIDED: "Void edilmiş kayıt",
            CANCELED: "Acente talebiyle iptal kayıt",
            EXPIRED: "Tarihi geçmiş kayıt",
            REFUNDED: "İade edilmiş kayıt (Refund)",
          },
          inputPlaceholder: "Lütfen seçiniz",
          inputValue: currentStatus,
          showCancelButton: true,
        }).then(function (result) {
          if (result.hasOwnProperty("value")) {
            const forms = document.forms;
            const url = changePnrStatusURL(bookingKey, pnrId,result.value);
            if (forms.length > 0) {
              const form = forms[0];
              const data1 = new FormData(form);
              data1.append("bookingKey", bookingKey);
              fetcherWithoutJSON(url)
              form.submit();
            } else {
              console.error("No forms found on the page.");
            }
          }
        });
      }
      const handleConfirm = async () => {
        try {
          const response = await fetcher(addFlightSegment(bookingKey, id));
          if (response.success) {
            setMessage("Başarıyla Eklendi.");
            setSeverity("success");
            Swal.fire({
              title: 'Success',
              text: 'Başarıyla Eklendi.',
              icon: 'success',
              confirmButtonText: 'Tamam',
            }).then(() => {
              window.location.reload();
            });
            
          } else {
            setMessage("Segment eklenemedi.");
            setSeverity("error");
            Swal.fire({
              title: 'Error',
              text: 'Segment eklenemedi.',
              icon: 'error',
              confirmButtonText: 'Tamam',
            });
          }
        } catch (error) {
          console.error("Error occurred:", error);
          setMessage("Segment eklenemedi.");
          setSeverity("error");
          Swal.fire({
            title: 'Error',
            text: 'Segment eklenemedi.',
            icon: 'error',
            confirmButtonText: 'Tamam',
          });
        }
      };
      
    
      const handleAddSegment = () => {
        setIsDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };
      return isAdmin  ? (
        <div>
          <div>
            <div className="sm:flex sm:justify-center">
              <form>
                <input
                  type="hidden"
                  name="bookingKey"
                  value={bookingKey}
                ></input>
              </form>
              <div
                className="mb-2 inline-flex flex-wrap rounded-md shadow-sm"
                role="group"
              >
                <button
                  id="refreshPnr"
                  type="button"
                  onClick={() => {
                    const button = document.getElementById("refreshPnr") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                    refreshPnr(filekey)}}
                  className="text-sm rounded-l-lg border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
                >
                  <i className="fa fa-refresh px-1"></i> Refresh
                </button>
                <UpdatePaxDialog bookingKey={bookingKey} filekey={filekey}/>
                <button
                onClick={handleAddSegment}
                  type="button"
                  className="text-sm  border space-x-2 border-gray-100 bg-white px-4 py-2 font-medium text-gray-900  hover:bg-gray-100 hover:text-blue-700 "
                >
                  <i className="fa fa-plus px-1"></i>
                  Segment Ekle
                </button>
                <UpdateFlightDialog bookingKey={bookingKey} filekey={filekey}/>
               
                <button
                  type="button"
                  onClick={() => changePnrStatus(bookingKey, id, status)}
                  className="text-sm rounded-r-lg border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900  hover:bg-gray-100 hover:text-blue-700 "
                >
                  <i className="fa fa-exchange px-1"></i>
                  Change PNR
                </button>
                
              </div>
            </div>
          </div>
          <ConfirmationDialog
  isOpen={isDialogOpen}
  onClose={handleCloseDialog}
  onConfirm={handleConfirm}
  message={
    <>
      <div><span className="text-primary-6000 font-semibold"> PNR: </span> {bookingKey}</div>
      <div><span className="text-primary-6000 font-semibold"> PNR ID: </span>{id}</div>
      <div className="py-1">Lütfen yukarıdaki bilgilerle segment eklemeyi onaylayın</div>
    </>
  }
/>

        </div>
      ) : null;
    };

    function copyButtonClickHandler(str: string) {
      // event.preventDefault();
      navigator.clipboard.writeText(str);
    }
    ///rescanPNR
    async function rescanPnrCreate(bookingKey: string, filekey: string) {
      const payload = {
        bookingKey: bookingKey,
        filekey: filekey,
      };

      try {
        setLoading(true);
        const response = await postFetcher(
          postRescanPnr(),
          payload
        );
      //  { if(response.hasOwnProperty('status')) {
      //     Swal.fire(t("app.general-error"), "error");
      //     setLoading(false)
      //     return
      //   }}
        const old: number = parseFloat(response.oldPrice);
        const newPrice: number = parseFloat(response.newPrice);
        const diff: number = newPrice - old;
        const newDiff = Math.abs(diff).toFixed(2);
        const depFlightRecommendation = response.depFlightRecommendation;
        const retFlightRecommendation = response.retFlightRecommendation;
        let diffColor: string = "green";
        let diffSign: string = "-";
        if (diff > 0) {
          diffColor = "red";
          diffSign = "+";
        }

        Swal.fire({
          title: t("flightbooking.options.query-current-price"),
          html: `<span style="font-weight:500">
                    ${t("generals.old-price")}: <b>${old.toFixed(2)} EUR</b><br>
                    <b style="color:${diffColor}">
                        ${t("generals.difference")}: ${diffSign}${newDiff} EUR
                    </b><br>
                    ${t("generals.new-price")}: <b>${newPrice.toFixed(2)} EUR</b>
                </span>`,
          showCancelButton: true,
          confirmButtonText: `<i class="fa fa-plus" aria-hidden="true"></i> ${t("flights.reserve")}`,
          cancelButtonText: `<i class="fa fa-close" aria-hidden="true"></i> ${t("generals.abort")}`,
        }).then(async (result) => {
          setLoading(false);

          if (result.isConfirmed) {
            const payload1 = {
              bookingKey: bookingKey,
              filekey: filekey,
              depFlightRecommendation: depFlightRecommendation,
              retFlightRecommendation: retFlightRecommendation,
            };


            try {
              setLoading(true);
              const response1 = await postFetcher(
                postRescanPnrtakeReservation(),
                payload1
              );
              const newBookingKey = response1.newBookingKey;
              setLoading(false);

              if (!response1.hasOwnProperty("status") || response1.status === "error") {
                Swal.fire(t("app.general-error"), response1.reason, "error");
                return;
              }

              Swal.fire({
                title: t("generals.success"),
                showConfirmButton: false,
                html: `<span style="font-weight:500">
                            <br>
                            <br>
                            JUPITER Key: <a href="https://www.atrtouristik.com/ibe#/booking/detail/${newBookingKey}" 
                                    target="_blank" class="btn btn-success ml-2">
                                    <i class="mdi mdi-open-in-new"></i> ${newBookingKey}
                                </a>
                            <br>
                        </span>`,
              });
            } catch (error) {
              setLoading(false);
              Swal.fire(t("app.general-error"), response.reason, "error");
              console.error('API request failed with error:', error);

            }
          }
        });
      } catch (error) {
        setLoading(false);
        Swal.fire(t("app.general-error"), "error");
        console.error('API request failed with error:', error);

      }
    }


    function DeletePnr(formElement: HTMLFormElement, filekeyPnr: string) {

      const confirmation = window.confirm(
        t("generals.are-you-sure-you-want-to-proceed")
      );

      if (confirmation) {
        setLoading(true);

        if (formElement.length > 0) {

          formElement.action = getStaticUrl('b2b') + `/agency/deletePnr?filekey=${filekeyPnr}`;
          formElement.addEventListener('submit', () => {
            setLoading(false);
          });
          formElement.submit();
        } else {
          setLoading(false);
          console.error("No forms found on the page.");
        }
      }
      else
      setLoading(false);
    }
    async function validateRefund(filekey: string): Promise<void> {
      const payload = {
        bookingKey: bookingKey,
        filekey: filekey}
      try {
        setLoading(true);
        const res = await postFetcher(
          postValidateRefundUrl(),
          payload
        );

        if (res) {
          const refunddata = await res;
          setLoading(false)
          window.refundToken = refunddata.refundToken;
  
          console.log("Validate Refund succesfully loaded.");
          if ( 
            window.confirm(`${t("flightbooking.refund.the-total-refund-amount-you-will-get")}
                   ${parseFloat(refunddata.refundAmount).toFixed(
                     2
                   )} €\n\n
                  ${t("flightbooking.refund.attention-no-further-action-possible-after-refund")}\n\n
                  ${t("generals.are-you-sure-you-want-to-proceed")}`)
          ) {
            await processRefund(filekey);
          }
          else return
        }
        else{ 
           setMessage(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically")) ;
           setSeverity('error');
           Swal.fire(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically"), "error");
          }
    
      } catch (err) {
        console.error(err);
        setMessage(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically")) ;
        setSeverity('error');
        Swal.fire(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically"), "error");

      } finally {
        setLoading(false);
      }
    }

    async function processRefund(filekey: string): Promise<void> {
      const payload = {
        bookingKey: bookingKey,
        filekey: filekey,
        token: window.refundToken
      }
      try {
      setLoading(true)
        const res = await postFetcher(postAgenyRefundUrl(),payload);

        if (res.success){
          setLoading(false);
          Swal.fire({
            title: t("generals.reservationstate-description.refunded"),
            icon: "success"
          });
          setTimeout(() => window.location.reload(), 2000);
        }
       
      } catch (err) {
        console.error(err);
        Swal.fire(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically"), "error");
      } finally {
        setLoading(false);
      }
    }

    return (
      <div>
     

        {data.routes.map((row) => (
          <div key={row.id}>
            <div className="my-4 mx-2 border rounded bg-white shadow-sm ">
              <div className="mb-1 grid grid-cols-2">

              <div className="flex justify-start font-bold text-lg py-2 items-center gap-2 pl-4">
                  <i className="fa fa-plane" aria-hidden="true"></i>{" "}
                  {data.routes.length === 2
                    ? data.routes[0].id === row.id
                      ? t("flightbooking.departure_flights")
                      : t("flightbooking.return_flights")
                    : t("bookings.flights")}
                </div>
             <div className="flex justify-end">
             <div
                  className={`flex items-center rounded-l px-3 h-7 text-md
                            ${row.status === "TICKETED" ? "bg-green-400" : ""}
                            ${row.status === "RESERVATION" ? "bg-blue-400" : ""}
                            ${row.status === "CANCELED" ? "bg-red-400" : ""}
                            ${row.status === "VOIDED" ? "bg-red-400" : ""}
                            ${row.status === "REFUNDED" ? "bg-gray-400" : ""}
                            ${row.status === "EXPIRED" ? "bg-red-400" : ""}

`}
                >
                  {row.status === "RESERVATION" ? (
                    <>
                      {t("flightbooking.ticketing_deadline")}:{" "}
                      {getDateTime(row.optionDate || "", 'date')} {"  "}
                      {getDateTime(row.optionDate || "", 'hour')}
                    </>
                  ) : null}
                  {row.status === "TICKETED" ? (
                    <>{t("flightbooking.ticketed")}</>
                  ) : null}
                  {row.status === "CANCELED" ? (
                    <>{t("flightbooking.canceled")}</>
                  ) : null}
                  {row.status === "VOIDED" ? <>Voided Kayit</> : null}
                  {row.status === "REFUNDED" ? (
                    <>{t("flightbooking.refunded")}</>
                  ) : null}
                  {row.status === "EXPIRED" ? (
                    <>{t("flightbooking.expired")}</>
                  ) : null}

                </div>
                          {row.airlineFilekey !== null ? (
              isAdmin ? (
                  <>
                      <button
                          className="rounded-r px-3 items-center bg-gray-100 h-7 text-md"
                          onClick={() => copyButtonClickHandler(row.airlineFilekey as string)}
                      >
                          <span className="text-base mr-2 font-semibold">
                              {row.airlineFilekey}
                          </span>
                          <i className="fa fa-copy" aria-hidden="true"></i>
                      </button>
                      <button
                          className="rounded-r px-3 items-center bg-gray-100 h-7 text-md"
                          onClick={() => copyButtonClickHandler(row.filekey )}
                      >
                          <span className="text-base mr-2 font-semibold">
                              GDS: {row.filekey}
                          </span>
                          <i className="fa fa-copy" aria-hidden="true"></i>
                      </button>
                  </>
              ) : (
                  <button
                      className="rounded-r px-3 items-center bg-gray-100 h-7 text-md"
                      onClick={() => copyButtonClickHandler(row.airlineFilekey as string)}
                  >
                      <span className="text-base mr-2 font-semibold">
                          {row.airlineFilekey}
                      </span>
                      <i className="fa fa-copy" aria-hidden="true"></i>
                  </button>
              )
          ) : (
              <button
                  className="rounded-r px-3 items-center bg-gray-100 h-7 text-md"
                  onClick={() => copyButtonClickHandler(row.filekey)}
              >
                  <span className="text-base mr-2 font-semibold">
                      {row.filekey}
                  </span>
                  <i className="fa fa-copy" aria-hidden="true"></i>
              </button>
          )}

             </div>
              </div>
              <div className="grid gap-4">
              {row.trips?.map((trip, index: number) => (
                     <div className="grid border rounded m-1 p-2" >
                     <h2 className="flex justify-start font-semibold text-primary-6000 pl-4">{index + 1}. Uçuş </h2>
                     <FlightTable flights={trip.flights} />
                    {row.status === "TICKETED" && row.reissueAvailable && row.airlineSystem !== "GALL" && <ReissueModal route={row} bookingKey={bookingKey} tripIndex={index} />}
                      {row.status ==="TICKETED" && row.reissueAvailable && row.airlineSystem === "GALL" && isAdmin && <ReissueModal route={row} bookingKey={bookingKey} tripIndex={index} /> }
                     </div>
                     ))
                    }
                  {audit.marketingType === "B2B" && internalButtonsFlight(bookingKey, row.filekey, row.id, row.status)}
              </div>
            
            <div className="flex justify-center">

            {(row.status === "CANCELED" ||
              row.status === "EXPIRED" ||
              row.status === "VOIDED" ||
              row.status === "REFUNDED") &&  audit.marketingType === "B2B"  &&(
              <div>
                <form id={`form_${row.filekey}`}>
                  <input
                    type="hidden"
                    name="bookingKey"
                    value={bookingKey}
                  ></input>
                  <input
                    type="hidden"
                    name="filekey"
                    value={row.filekey}
                  ></input>
                </form>
                <button
                id="deletePnr"
                  type="button"
                   onClick={() =>{
        const button = document.getElementById("deletePnr") as HTMLButtonElement | null;
                  if (button) {
                    disableButton([button]);
                 }
        DeletePnr(document.getElementById(`form_${row.filekey}`) as HTMLFormElement,row.filekey)}}
      className="text-sm rounded border bg-red-500 px-4 py-2 font-medium  text-white hover:border-red-600 hover:text-red-300 "
    >
      <i className="fa fa-trash" aria-hidden="true"></i>{" "}
      {t("generals.delete")}
    </button>
  </div>
)}
              {row.rescanAvailable && audit.marketingType === "B2B" &&
                (row.status === "CANCELED" || row.status === "EXPIRED") && (
                  <div className="text-center">
                    <button
                    id="rescanPnrCreate"
                      type="button"
                      className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700"
                      onClick={() => {
                        const button = document.getElementById("rescanPnrCreate") as HTMLButtonElement | null;
                        if (button) {
                          disableButton([button]);
                       }
                        rescanPnrCreate(bookingKey, row.filekey)}}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                      {t("flightbooking.options.create-new-reservation")}
                    </button>
                  </div>
                )}

              { audit.marketingType === "B2B" && <ReservationOptionsButtons row={row} rescanPnrAllowed={row.rescanAvailable} bookingKey={bookingKey}/>}

              {row.status === "TICKETED" && audit.marketingType === "B2B" && row.refundAvailable ? (
                <><button
                    id="validateRefund"
                    type="button"
                    className="text-sm border border-gray-200 rounded mt-0.5 bg-red-500 px-4 py-2 font-medium text-white hover:shadow-lg hover:text-red-700 focus:z-10 "
                    onClick={() => {
                      const button = document.getElementById("validateRefund") as HTMLButtonElement | null;
                      if (button) {
                        disableButton([button]);
                      }
                      validateRefund(row.filekey);
                    } }
                  >
                    <i className="fa fa-times"></i>
                    <span> {t("flightcases.refund-request")}</span>
                  {loading &&  <p><CircularProgress className="text-white" /> </p>}
                  </button></>
              ) : null}
              </div>
            { row.status === "TICKETED" && <SsrSection route={row} filekey={row.filekey} bookingKey={bookingKey} />}        
            { audit.marketingType === "B2B" && <div className="item-center space-x-3 mt-2  justify-end  bg-white px-4 font-semibold sm:flex">
           <span>
            {row.status === "RESERVATION" && 
            <span className="text-sm font-normal px-4">*{t("flight.airline.rights")}</span>
            }
           {currencyFormat(row.totalAmount)}

           </span>
              </div>}    </div>
          </div>
        ))}
        <ToastNotification message={message} severity={severity} resetNotification={resetNotification} />
      </div>
    );
  };
  export default FlightDetail 