import { StarRate } from "@mui/icons-material";
import { HotelPackageTour } from "api/types";
import StartRating from "components/common/StartRating";
import React from "react";
import { FaMapPin, FaSun, FaThumbsUp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

interface Hotel {
  hotel: HotelPackageTour;
}

const HotelHeader: React.FC<Hotel> = ({ hotel }) => {
  return (
    <div className="overflow-hidden rounded border border-neutral-100 bg-white">
      <div className="grid grid-cols-4 p-2">
        <div className="">
          <img
            src={
              hotel.images.find((image) => image.imageSize === "LARGE")?.url ||
              ""
            }
            alt="Profile"
            className="object-fit h-auto rounded border"
          />
        </div>

        <div
          className="col-span-3 py-1 px-3
        "
        >
          <div className="mb-2 text-[20px] font-bold">
            {hotel.hotelName} <StartRating point={parseInt(hotel.stars)} />{" "}
          </div>
          <div className="d-flex">
            <div className="mr-2 flex h-8 w-40 items-center justify-center space-x-1 rounded border bg-primary-6000 text-white">
              <FaThumbsUp />
              <span className="text-sm font-semibold">
                {Math.round(hotel.review.reviewRecommendationRate)}%
              </span>
            </div>
            <div className="flex h-8 w-40 items-center justify-center space-x-1 rounded-lg border-2  bg-gray-100 text-black">
              <FaSun />
              <span className="text-sm font-semibold">
                {hotel.review.reviewPoint}/6
              </span>
            </div>
          </div>
          <div className=" text-sm my-2 items-center space-x-1 text-gray-600">
            <div className="mr-1 flex justify-center items-center space-x-2 ">
              <IoLocationSharp className="mr-2 h-4 w-4" />
              <span>{hotel.cityName}</span>
                <span className="font-semibold text-l">{hotel.countryName}</span>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelHeader;
