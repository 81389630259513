import React, {FC, useEffect, useState} from "react"

import 'primeicons/primeicons.css';
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {Button} from "primereact/button";
import {
    AirportSearchAutocompleteResult,
    BestPricesModel,
    CabinClass, IMissingInputError, MultiCitySearchModel,
    SearchPayload,
    TFlightType
} from "../../../../api/types";
import {TravelersInputValue} from "../TravelersInput";
import {b2cURL, getAirportAutocompleteUrl, getBestPricesUrl} from "../../../../api/helpers/api-constants";
import {MobileAutoCompleteFlightDestination} from "../../SectionHeroArchivePage/MobileAutoCompleteFlightDestination";
import {MobileCustomCalendar} from "../../CustomCalendar/MobileCustomCalendarDialog";
import {MobileFlightTypeSelection} from "../../SectionHeroArchivePage/MobileFlightTypeSelection";
import {MobileFlightClassSelection} from "../../SectionHeroArchivePage/MobileFlightClassSelection";
import {MobileFlightPassengerSelection} from "../../SectionHeroArchivePage/MobileFlightPassengerSelection";
import { MobileAutoComplete } from "components/common/SectionHeroArchivePage/MobileAutoComplete";
import { fetcherWithoutJSON } from "api/helpers/fetcher";
import {useReduxReducer} from "../../../../redux/store";
import Switch from "components/ui/Switch/Switch";


interface IFlightSearchFormMobile {
    queryParameters: SearchPayload | null
    flightType: TFlightType
    departureAirport: AirportSearchAutocompleteResult|null
    arrivalAirport: AirportSearchAutocompleteResult|null
    selectedDate: [moment.Moment|null, moment.Moment|null]
    travelers: TravelersInputValue
    cabinClass: CabinClass
    multiCitySearch: MultiCitySearchModel[]
    multiCitySearchNumber: number
    missingInputError: IMissingInputError
    withBaggageOnly: boolean;
    setWithBaggageOnly : React.Dispatch<React.SetStateAction<boolean>>;
    setFlightType: (value: TFlightType) => void
    setDepartureAirport: (value: AirportSearchAutocompleteResult) => void
    setArrivalAirport: (value: AirportSearchAutocompleteResult) => void
    setSelectedDate: (value: [moment.Moment|null, moment.Moment|null]) => void
    setTravelers: (value: TravelersInputValue) => void
    setCabinClass: (value: CabinClass) => void
    setMultiCitySearch: (value: MultiCitySearchModel[]) => void
    setMultiCitySearchNumber: React.Dispatch<React.SetStateAction<number>>
    setMissingInputError: (value: IMissingInputError) => void
    handleSubmit: (value: React.FormEvent) => void
}

export const FlightSearchFormMobile: FC<IFlightSearchFormMobile> = (
    {
        queryParameters,
        flightType,
        departureAirport,
        arrivalAirport,
        selectedDate,
        travelers,
        cabinClass,
        multiCitySearch,
        multiCitySearchNumber,
        missingInputError,
        withBaggageOnly,
        setWithBaggageOnly,
        setFlightType,
        setDepartureAirport,
        setArrivalAirport,
        setSelectedDate,
        setTravelers,
        setCabinClass,
        setMultiCitySearch,
        setMultiCitySearchNumber,
        setMissingInputError,
        handleSubmit
    }) => {
    const { t } = useTranslation()

    const { isB2C } = useReduxReducer(state => state.general)

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ bestPrices, setBestPrices ] = useState<BestPricesModel[]>([])

    useEffect(() => {
        if (departureAirport && arrivalAirport) {
            setIsLoading(true)
            getPrices(departureAirport.id, arrivalAirport.id, moment().format('DD.MM.YYYY'), moment().add(10, 'month').format('DD.MM.YYYY'))
        }
    }, [departureAirport, arrivalAirport])

    useEffect(() => {
        if(flightType === "Oneway" && selectedDate[0] && selectedDate[1])
            setSelectedDate([selectedDate[0], null])
        else if(flightType === "Round-Trip" && selectedDate[0])
            setSelectedDate([selectedDate[0], moment(selectedDate[0]).add(5, "day")])
    }, [flightType])

    const updateDepartureAirportMulti = (index: number, value: AirportSearchAutocompleteResult) => {
        const _multiCity: MultiCitySearchModel[] = []

        multiCitySearch.map((searchModel, _index) => {
            if (index === _index)
                _multiCity.push({...searchModel, departureAirport: value})
            else
                _multiCity.push(searchModel)
        })

        setMultiCitySearch(_multiCity)
    }

    const updateArrivalAirportMulti = (index: number, value: AirportSearchAutocompleteResult) => {
        const _multiCity: MultiCitySearchModel[] = []

        multiCitySearch.map((searchModel, _index) => {
            if (index === _index)
                _multiCity.push({...searchModel, arrivalAirport: value})
            else
                _multiCity.push(searchModel)
        })

        setMultiCitySearch(_multiCity)
    }

    const updateSelectedDateMulti = (index: number, value: [moment.Moment|null, moment.Moment|null]) => {
        const _multiCity: MultiCitySearchModel[] = []

        multiCitySearch.map((searchModel, _index) => {
            if (index === _index)
                _multiCity.push({...searchModel, departureDate: value[0]})
            else
                _multiCity.push(searchModel)
        })

        setMultiCitySearch(_multiCity)
    }

    const getPrices = async (from: string, to: string, startDate: string, endDate: string) => {

        const url = `${isB2C ? b2cURL : ''}${getBestPricesUrl(from, to, startDate, endDate)}`
        let _bestPrices: BestPricesModel[] = []

        await fetcherWithoutJSON(url).then(res => res.json())
            .then(data => {
                if (data.status !== 'error') {
                    _bestPrices = data
                }
            })
            .catch(err => {
                console.error('err', err)
            })
            .finally(() => {
                setIsLoading(false)
                setBestPrices(_bestPrices)
            });
    }

    const switchDestination = (index?: number) => {
        if (flightType !== 'Multi-City') {
            if (departureAirport && arrivalAirport) {
                const _tmp: AirportSearchAutocompleteResult = departureAirport
                setDepartureAirport(arrivalAirport)
                setArrivalAirport(_tmp)
            }
        } else {
            if (multiCitySearch[index!].departureAirport && multiCitySearch[index!].departureAirport) {
                const _multiCitySearch: MultiCitySearchModel[] = []
                if (_multiCitySearch) {
                    multiCitySearch.map((_multiCity, _index) => {
                        if (index === _index) {
                            const _tmpDepartureAirport: AirportSearchAutocompleteResult|null = _multiCity.departureAirport
                            const _tmpArrivalAirport: AirportSearchAutocompleteResult|null = _multiCity.arrivalAirport

                            if (_tmpDepartureAirport && _tmpArrivalAirport) {
                                _multiCitySearch.push({..._multiCity, departureAirport: _tmpArrivalAirport, arrivalAirport: _tmpDepartureAirport})
                            }
                        } else {
                            _multiCitySearch.push(_multiCity)
                        }
                    })
                }
                setMultiCitySearch(_multiCitySearch)
            }
        }
    }

    const addFlight = () => {
        setMultiCitySearchNumber(multiCitySearchNumber + 1)
        const _multiCitySearch: MultiCitySearchModel[] = multiCitySearch
        _multiCitySearch.push({
            departureAirport: null,
            arrivalAirport: null,
            departureDate: null,
        })
        setMultiCitySearch(_multiCitySearch)
    }

    const removeFlight = (index: number) => {
        setMultiCitySearchNumber(multiCitySearchNumber - 1)
        multiCitySearch.splice(index, 1)
        setMultiCitySearch(multiCitySearch)
    }

    const searchMaskContent = (index?: number) => {
        return (
            <>
                <div className={`justify-center items-center`}>
                    <div className={`relative`}>
                        <div className={'w-full'}>
                        
                            <MobileAutoComplete
                                className={'rounded-t-lg bg-gray-200'}
                                defaultValue={t('rentalcar.flight-departure-port')}
                                selectedDestination={ flightType !== 'Multi-City' ? departureAirport : multiCitySearch[index!].departureAirport}
                                setDestination={value => flightType !== 'Multi-City' ? setDepartureAirport(value) : updateDepartureAirportMulti(index!, value)}
                                getUrl={getAirportAutocompleteUrl}
                                placeholder={t("app.flight-search.from")}
                            />
                        </div>
                        <div className={'w-full mt-0.5'}>
                            <MobileAutoComplete
                                className={'rounded-b-lg bg-gray-200'}
                                defaultValue={t('react.flight.generel.targetairport')}
                                selectedDestination={ flightType !== 'Multi-City' ? arrivalAirport : multiCitySearch[index!].arrivalAirport}
                                setDestination={value => flightType !== 'Multi-City' ? setArrivalAirport(value) : updateArrivalAirportMulti(index!, value)}
                                getUrl={getAirportAutocompleteUrl}
                                placeholder={t("app.flight-search.to")}
                            />
                        </div>
                        <Button
                            className={`absolute top-6 right-4 rounded-lg h-10 w-10 bg-white`}
                            icon="pi pi-sort-alt"
                            text
                            onClick={() => flightType !== 'Multi-City' ? switchDestination() : switchDestination(index!)}
                        />
                    </div>
                </div>
                <div className={'w-full mt-2.5'}>
                    <MobileCustomCalendar
                        isLoadingBestPrices={isLoading}
                        bestPrices={bestPrices}
                        range={flightType === "Round-Trip"}
                        selectedDate={flightType !== "Multi-City" ? selectedDate : [multiCitySearch[index!].departureDate, null]}
                        setSelectedDate={(value) => flightType !== "Multi-City" ? setSelectedDate(value) : updateSelectedDateMulti(index!, value)}
                    />
                </div>
            </>
        )
    }

    const multiCitySearchMask = () => {
        const idx: any[] = Array.from({length: 5})

        return (
            <>
                {
                    idx.slice(0, multiCitySearchNumber).map((item, index) => (
                        <div key={index}>
                            <div className={`${index === 0 ? '' : 'pt-5'} pb-2.5 font-semibold flex justify-between w-full`}>
                                <div className={`text-left`}>{`${index+1}. Flug`}</div>
                                <div
                                    className={`${(index < 2 && multiCitySearchNumber === 2) ? 'text-gray-400' : 'text-red-500 cursor-pointer'} text-right`}
                                    onClick={() => removeFlight(index)}
                                >
                                    {`Entfernen`}
                                </div>
                            </div>
                            <div>{ searchMaskContent(index) }</div>
                        </div>
                    ))
                }
            </>
        )
    }

    return (
        <div className={`bg-white`}>
            <div className={`pt-2`}>
                <MobileFlightTypeSelection
                    flightType={flightType}
                    setFlightType={(value: TFlightType) => setFlightType(value)}
                />
            </div>
            <div className={`pt-2`}>
                <MobileFlightClassSelection
                    cabinClass={cabinClass}
                    setCabinClass={(value: CabinClass) => setCabinClass(value)}
                />
            </div>
            <div className={`pt-2`}>
                <MobileFlightPassengerSelection
                    adtCount={travelers.adults}
                    chdCount={travelers.children}
                    infCount={travelers.infants}
                    setTravelers={(value: TravelersInputValue) => setTravelers(value) }
                />
            </div>
            <div className={`pt-3`}>
                {
                    flightType === 'Multi-City' ?
                        <>
                            {
                                multiCitySearchMask()
                            }
                            <div
                                className={`${multiCitySearchNumber < 5 ? 'cursor-pointer text-blue-400' : 'text-gray-400'} flex justify-end items-center font-semibold text-sm py-3 border-b`}
                                onClick={() => {
                                    if (multiCitySearchNumber < 5)
                                        addFlight()
                                }}
                            >
                                <i className="pi pi-plus" style={{ fontSize: '0.8rem' }}></i>
                                &nbsp;&nbsp;&nbsp;
                                <span>Weitere hinzufügen</span>
                            </div>
                        </>
                        :
                        searchMaskContent()
                }
            </div>
            <div className="px-2 flex pt-2 justify-end">
          <span className="text-base"> {t("b2c-app.flights.results.filter.baggage.with-baggage")}</span>
          <Switch
                                id="customSwitch"
                                checked={withBaggageOnly}
                                onChange={() => setWithBaggageOnly((prev) => !prev)}
                                labelClass=""
                                className=""
                              />
           </div>
            <div className={`pt-3`}>
                <button
                    className="flex h-14 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none md:h-12 font-semibold"
                    onClick={(e) => handleSubmit(e)}
                >{t('general.search.flight')}</button>
            </div>
        </div>
    )
}
